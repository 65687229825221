.events {
  background-color: var(--theme-content-color);
  color: var(--theme-background-color);
  padding: 45px 0;
  position: relative;
}

.events h1 {
  font-size: 30px;
  text-align: center;
  padding-top: 20px;
}

.events h2 {
  font-size: 24px;
  line-height: 1.25;
}

.events p {
  padding-bottom: 0;
}

.events-border {
  border-style: solid;
  border-color: var(--theme-border-color);
}

.instagram-embed-container {
  align-items: center;
  display: flex;
  flex-direction: column;
}

div.instagram-embed-post-container {
  position: relative;
  max-width: 400px;
}

div.instagram-embed-header {
  background-color: var(--theme-content-color);
  height: 65px;
  position: absolute;
  right: 0;
  top: -1px;
  width: 330px;
}

.no-events-container {
  background-color: var(--theme-background-color);
  width: 80%;
}

.no-events-message {
  color: var(--theme-content-color);
  font-size: 18px;
  margin: 0 auto;
}

@media screen and (min-width: 992px) {
  .instagram-embed-container {
    flex-direction: row;
    justify-content: space-evenly;
  }

  .instagram-embed {
    margin: 15px;
  }

  div.instagram-embed-header {
    height: 70px;
    width: 400px;
  }
}

/**
 * Here we override the InstagramEmbed component and its iframe
**/
div.rsme-embed.rsme-instagram-embed {
  max-height: 460px;
}

div.rsme-embed.rsme-instagram-embed > iframe {
  border: none !important;
}
